import { FieldProps } from "formik";
import React from "react";
// import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { Input, InputProps } from "reactstrap";

// type InputProps = DetailedHTMLProps<
//   InputHTMLAttributes<HTMLInputElement>,
//   HTMLInputElement
// >;

export const InputField = ({
  field,
  form,
  ...props
}: FieldProps & InputProps) => {
  const isInvalid: boolean = !!(form && form.errors[field.name] && form.touched[field.name]);
  return (
    <>
      <Input {...field} {...props} invalid={isInvalid} />
      {isInvalid && <div className="invalid-feedback">{form.errors[field.name]}</div>}
    </>
  );
};
